export default function Pagination({ current_page, num_pages }) {
  return (
    <div>
      <div className="flex items-center justify-between px-4 py-3 sm:px-6 mt-8">
        <div className="flex flex-1 justify-between sm:hidden">
          <a
            href="#"
            className="relative inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50"
          >
            Previous
          </a>
          <a
            href="#"
            className="relative ml-3 inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50"
          >
            Next
          </a>
        </div>
        <div className="hidden sm:flex sm:flex-1 sm:items-center sm:justify-center">
          <div>
            <nav
              aria-label="Pagination"
              className="isolate inline-flex -space-x-px rounded-md shadow-xs"
            >
              {(() => {
                const elements = [];
                for (let i = 1; i <= num_pages; i++) {
                  elements.push(
                    <a
                      key={i}
                      href={`/blog/${i}`}
                      className={`${
                        i == current_page
                          ? "bg-[#c470db]"
                          : "bg-white hover:bg-gray-200"
                      } relative inline-flex items-center px-4 py-2 text-sm font-semibold text-gray-900 border border-gray-200 focus:z-20 focus:outline-offset-0`}
                    >
                      {i}
                    </a>
                  );
                }
                return elements;
              })()}
            </nav>
          </div>
        </div>
      </div>
    </div>
  );
}
