export const mock_data = [
  {
    id: "Pig-butchering-a-scam-you-should-know",
    imageUrl: "/blog_image_2.png",
    title: "Pig Butchering: A Scam You Should Know",
    description:
      "Pig butchering scams are a form of financial fraud where scammers build trust before exploiting victims for money. They use emotional and psychological manipulation, creating fake relationships or investment opportunities to deceive their targets.",
    content: `<p>Pig butchering scams are a form of financial fraud where scammers build trust before exploiting victims for money. They use emotional and psychological manipulation, creating fake relationships or investment opportunities to deceive their targets.</p>
    <br>
    <br>
    These scams are highly organized, often run by criminal networks. Scammers bombard victims with fabricated success stories and fake investment dashboards to appear legitimate. Many scammers are also victims of human trafficking, forced to operate within these fraud schemes.
    <br>
    <br>
    The financial and emotional toll is severe, with victims losing life savings and struggling with shame and betrayal. As these scams evolve, awareness is crucial—understanding their tactics helps individuals recognize warning signs and avoid falling victim.
    `,

    date: "2025-03-29",
  },
  {
    id: "Phishing-scams-do-not-take-the-bait",
    imageUrl: "/blog_image_5.png",
    title: "Phishing Scams: Do not Take the Bait",
    description:
      "Phishing is a cyberattack where scammers impersonate trusted sources—like banks, employers, or tech companies—to trick individuals into revealing sensitive information. These attacks usually come via email, text, or fake websites designed to steal passwords, credit card numbers, or personal data.",
    content: `Phishing is a cyberattack where scammers impersonate trusted sources—like banks, employers, or tech companies—to trick individuals into revealing sensitive information. These attacks usually come via email, text, or fake websites designed to steal passwords, credit card numbers, or personal data.
<br>
<br>
Modern phishing scams are highly convincing, often using urgent language (“Your account is locked!”) to pressure quick action. Some even mimic legitimate login pages or spoof phone numbers.
<br>
<br>
To stay safe, always double-check URLs, avoid clicking suspicious links, and never share credentials via email or text. When in doubt, contact the organization directly using official channels.`,
    date: "2025-03-15",
  },
  {
    id: "Behind-the-hype-how-crypto-scams-really-work",
    imageUrl: "/blog_image_3.png",
    title: "Behind the Hype: How Crypto Scams Really Work",
    description:
      "Cryptocurrency scams exploit the rise of digital assets, tricking people into fraudulent investments, fake exchanges, and phishing attacks to steal funds or sensitive information. Scammers often pose as influencers, promote fake giveaways, or promise unrealistic profits to lure victims.",
    content: `Cryptocurrency scams exploit the rise of digital assets, tricking people into fraudulent investments, fake exchanges, and phishing attacks to steal funds or sensitive information. Scammers often pose as influencers, promote fake giveaways, or promise unrealistic profits to lure victims.
    <br>
    <br>
    Tactics include rug pulls, where developers hype a cryptocurrency before vanishing with investors’ money, and pump-and-dump schemes, where prices are artificially inflated before scammers cash out. With transactions on blockchain networks being irreversible, victims often face significant financial losses.
    <br>
    <br>
    As these scams evolve, awareness is crucial. Verifying platforms, recognizing red flags, and avoiding offers that seem too good to be true can help individuals protect themselves from crypto fraud.`,
    date: "2025-03-01",
  },
  {
    id: "$1.5b-gone-the-heist-that-shook-crypto",
    imageUrl: "/blog_image_4.png",
    title: "$1.5B Gone: The Heist That Shook Crypto",
    description:
      "In February 2025, crypto exchange Bybit suffered a massive hack that drained approximately $1.5 billion in Ethereum—marking the largest crypto heist to date. The attackers breached Bybit’s cold wallet, typically considered secure, and siphoned off around 400,000 ETH.",
    content: `In February 2025, crypto exchange Bybit suffered a massive hack that drained approximately $1.5 billion in Ethereum—marking the largest crypto heist to date. The attackers breached Bybit’s cold wallet, typically considered secure, and siphoned off around 400,000 ETH.
<br>
<br>
Blockchain analysts and the FBI linked the attack to North Korea’s Lazarus Group, part of an ongoing cybercrime campaign known as “TraderTraitor.” Despite the loss, Bybit’s CEO assured users that all client funds remain fully backed. Emergency funding from Galaxy Digital, FalconX, and Wintermute helped stabilize operations.
<br>
<br>
The incident has reignited concerns about crypto exchange security and the growing threat of state-sponsored cybercrime.`,
    date: "2025-02-15",
  },
  {
    id: "Social-engineering-when-the-weakest-link-is-human",
    imageUrl: "/blog_image_6.png",
    title: "Social Engineering: When the Weakest Link Is Human",
    description:
      "Scammers manipulate trust and urgency through phishing, fake investments, and impersonation, leading to financial loss and emotional distress. They often pose as banks, government agencies, or reputable companies to trick individuals into sharing sensitive information.",
    content: `
Social engineering is a form of manipulation where attackers trick people into revealing sensitive information or performing actions that compromise security. Unlike traditional hacks that target software, social engineering targets human psychology—making it one of the most dangerous and effective forms of cyberattack.
<br>
<br>
Common tactics include phishing emails, fake tech support calls, or impersonating trusted individuals to gain access to systems, passwords, or funds. These scams often rely on urgency, fear, or curiosity to get people to act without thinking.
<br>
<br>
The best defense? Stay skeptical. Verify identities, don’t share personal info over calls or messages, and always pause before clicking on unexpected links. In the world of cybersecurity, thinking twice can save you big.`,
    date: "2025-02-01",
  },
  {
    id: "The-ultimate-guide-to-avoiding-scams",
    imageUrl: "/blog_image_1.png",
    title: "The Ultimate Guide to Avoiding Scams",
    description:
      "Scammers manipulate trust and urgency through phishing, fake investments, and impersonation, leading to financial loss and emotional distress. They often pose as banks, government agencies, or reputable companies to trick individuals into sharing sensitive information.",
    content: `
Scammers manipulate trust and urgency through phishing, fake investments, and impersonation, leading to financial loss and emotional distress. They often pose as banks, government agencies, or reputable companies to trick individuals into sharing sensitive information.<br>
<br>
Falling victim to scams can result in drained savings, identity theft, and long-term financial damage. Beyond monetary loss, victims may experience shame, fear, and anxiety, making it harder to seek help or report fraud.
<br>
<br>
Awareness is the best defence. Recognizing red flags, verifying sources, and using security measures like two-factor authentication can significantly reduce the risk. Staying informed and cautious helps protect against evolving scams.`,
    date: "2024-01-18",
  },
];
