import Navbar from "./Navbar";
import Footer from "./Footer";

import { mock_data } from "../data/blog/data.js";
import Pagination from "./Pagination.jsx";
import { useParams } from "react-router-dom";

function Blog() {
  const { page } = useParams();
  const articles_per_page = 3;

  const totalPages = Math.ceil(mock_data.length / articles_per_page);
  const startIndex = (page - 1) * articles_per_page;
  const currentItems = mock_data.slice(
    startIndex,
    startIndex + articles_per_page
  );

  //const nextPage = () => {
  //  if (currentPage < totalPages) {
  //    setCurrentPage(currentPage + 1);
  //  }
  //};

  //const prevPage = () => {
  //  if (currentPage > 1) {
  //    setCurrentPage(currentPage - 1);
  //  }

  return (
    <div className="bg-[#282c33] h-screen">
      <div className="max-w-[1100px] mx-auto">
        <Navbar />
        <div className="pt-32">
          <h1 className="text-6xl text-white">BLOG</h1>
          <div className="grid sm:grid-cols-2 md:grid-cols-3 gap-6 mt-8">
            {currentItems.map((i) => (
              <a key={i.id} href={`/blog/article/${i.id}`}>
                <div className=" border-1 rounded-lg bg-white h-full">
                  <img
                    src={i.imageUrl}
                    alt=""
                    className="rounded-t-lg h-[250px] bg-gray-400 w-full object-cover"
                  />

                  <div className="p-4">
                    <h3 className="font-bold text-xl">{i.title}</h3>
                    <p className="mt-4">{i.date}</p>
                    <p className="line-clamp-3 mt-4">{i.description}</p>
                  </div>
                </div>
              </a>
            ))}
          </div>
          <Pagination current_page={page} num_pages={totalPages} />
        </div>
        <Footer />
      </div>
    </div>
  );
}

export default Blog;
